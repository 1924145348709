.app-startup {

    // Blok voor tijdelijke actie
    .promotional-block {
        display: block;
        font-weight: 600;
        text-align: center;
        margin: rem-calc(0 0 20);
        padding: rem-calc(10 50);
        background-color: #4ac13c;
        color: #fff;
    }
}