.contact-section {
    @extend .site-section;

    h1 {
        font-family: $body-font-family;
        line-height: 1.35;
        font-weight: 300;
        color: $primary-color;
        margin-bottom: rem-calc(20);
        text-align: center;
    }

    h2 {
        font-size: rem-calc(32);
        color: inherit;
        font-weight: 400;
    }

    h3 {
        font-size: rem-calc(16);
        color: #333;
        font-weight: 400;
        text-align: center;
    }
}