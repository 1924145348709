/** -------------------------------
 *  Frontpage Header
 *  -------------------------------
 */

.header {
    width: 100%;
    background: url('/img/sportbundels-background-02.jpg') no-repeat center 11% fixed;
    background-size: auto;
    position: relative;
    min-height: 750px;
    margin-top: -131px;
    z-index: 10;

    .intro-section {
        text-align: center;
        width: 100%;
        padding-top: 40px;
        overflow: hidden;
        position: relative;
        @include clearfix;

        @media #{$medium-up} {
            padding-top: 136px;
        }

        h1{
            font-size:rem-calc(52);
            font-weight: 600;
            color: #267EBD;

            b{
                font-weight: 600;
            }
        }

        h2 {
            font-size:rem-calc(20);
            font-weight: 200;
            color: #267EBD;
            font-style: italic;
            
            a{
                color: #80A6EC;
                border-bottom: 1px dashed #80A6EC;
            }
        }

        .buttons {
            margin-top: rem-calc(50);
        }
    }
}