.features {
    @extend .site-section;
    background: #f9fbfa;
    padding: rem-calc(40 0 10);

    h1 {
        padding: 0;
    }

    img {
        max-height: rem-calc(400);
    }
}