.cover {
    position: relative;
    width: 100%;
    margin-bottom: rem-calc(80);

    &.alternate {
        background: url('/img/sportbundels-background-01.jpg') no-repeat center center;
        background-size: cover;
        height: rem-calc(200);
        background-attachment: fixed;
    }
}