.pricing-section {
    @extend .site-section;

    h1 {
        font-family: $body-font-family;
        line-height: 1.35;
        font-weight: 300;
        color: $primary-color;
        margin-bottom: rem-calc(20);
        text-align: center;
    }
}

.included{
    margin-top: 50px;
    padding: 0 20px;
    text-align: center;

    ul{
        list-style: none;

        @media #{$medium} {
            padding: 0 40px;
        }
        @media #{$large} {
            padding: 0 180px;
        }
        li{
            font-size: 0.9em;
            line-height: 40px;
            border-bottom: 1px solid lightblue;

            &:last-child{
                border-bottom: 0;
            }
        }
    }
 }

.advantages {
    margin-top: 50px;
    width: 100%;
    background-color: rgba(144, 238, 144, 0.25);
    background: linear-gradient(to right, rgba(144, 238, 144, 0.25), rgba(50, 205, 50, 0.25));
    padding: 20px 0 50px;
}


#pricing{
    .bundels {
        padding: 0;
        margin-bottom: 30px;

        h3 {
            font-weight: 600;
            font-size: rem-calc(48);
            letter-spacing: -1px;
            margin: 0;

            sup {
                color: rgba(0, 0, 0, 0.60);
                font-size: 2rem;
                position: relative;
                top: 6px;
                right: 2px;
                margin-left: -18px;
                margin-right:0;
                font-weight: 200;
            }
        }
    }
}

.module-picker{
    border-radius: 10px;
    border:1px solid lightblue;
    margin:40px 20px;
    padding: 0 14px 20px;
    overflow: hidden;
    background: url("/img/pricing-bg.jpg") no-repeat bottom;
    box-shadow: 0 2px 16px #e0e0e0;

    i{
        width: 50px;
        text-align: center;
    }

    .module-icon{
        font-size: 48px;
    }
}

.module-picker-row{
    text-align: center;
    line-height: 40px;
    margin:14px 0;
    padding: 15px 6px 9px;
    border-radius: 4px;
    transition: background-color 0.08s ease-out;
    cursor: pointer;
    border: 1px solid transparent;
    
    @media #{$large-up} {
        padding: 35px 10px 28px;
    }
    
    &:hover{
        background-color: #eeeeee;
        border: 1px solid #cccccc;
    }

    &.active{
        background-color: #bdf7bc;
        border: 1px solid #83c582;
    }
}
.free {
    text-align: center;
    line-height: 40px;
    margin: 14px 0;
    padding: 15px 6px 9px;
    border-radius: 4px;
    transition: background-color 0.08s ease-out;
    cursor: pointer;
    background-color: rgba(189, 247, 188, 0.35);
    border: 1px solid rgba(131, 197, 130, 0.35);
}

.module-picker-prices{
    text-align: center;
    border-top: 1px solid lightblue;
    line-height: 40px;
    padding:20px 0;

    .price{
    }
    .price-number{
        font-size: 1.5em;
        font-weight: 400;
    }
    .discount{
    }
    .discount-number{
        font-size: 1.5em;
        font-weight: 400;
        color:#008000;
    }
    .total{
        background-color: #F0F9F1;
    }
    .total-number{
        background-color: #f0f9f1;
        font-size: 1.8em;
        font-weight: 600;
        padding-bottom: 6px;
    }
}

.module-picker-startcost{
    text-align: center;
    font-size: rem-calc(14);
    border-top:1px solid lightblue;
    line-height: 24px;
    padding:16px 0 0;
}


/* BUNDEL KOLOMMEN */

.bundel{
    margin-top: 10px;
    border: 1px lightgrey solid;

    border-radius: 3px;

    &.bottom{
        margin-bottom: 20px;
    }
}

.bundel-header{
    padding-top: 19px;
    text-align: center;
    background-color: whitesmoke;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    &.pb{
        padding-bottom: 37px;
    }

    &.compleet{
        background-color: #d4eaf6;
    }

    &.pro{
        background-color: #eaf6c1;
    }

    .discount{
        margin-top: 10px;
        height:27px;
        line-height: 27px;
        color: #333;
        width:100%;
        background-color: rgba(255,255,255,.5);
        font-weight: 600;
        position: relative;
        bottom:0;
    }
}

.bundel-name{
    font-size: rem-calc(18);
    letter-spacing: 2px;
}

.bundel-per-maand{
    font-size: rem-calc(14);
    color: rgba(0, 0, 0, 0.60);
}

.bundel-details{

    ul {
        margin: 0;
        padding: 35px;
        text-align: left;

        @media #{$small-only} {
            text-align: center;
        }

        li{
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: rem-calc(14);
            color: rgba(74,74,74,0.81);
            line-height: 1.4;
        }
        li + li{
            margin-top: 20px;
        }
        
        .price{
            float: right;
            margin-top: 20px;
            color: #AAA;
        }
    }
}

.monthly-pay{
    text-align: center;
    color: #999;
    font-size: .8em;
}

.bundel-wip{
    padding: 10px 0;
    text-align: center;
    border-left: 1px lightgrey solid;
    border-right: 1px lightgrey solid;

    p{
        font-size: rem-calc(14);
        margin: 0;
        padding: 0;
    }

    b{
        font-weight: 600;
    }
}
