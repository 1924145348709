.site-section {
    margin: rem-calc(30 0);
    font-size: rem-calc(16);
    line-height: 1.35;

    @media #{$medium-up} {
        margin: rem-calc(80 0);
    }

    @media #{$small-only}{
        p {
        text-align: justify;
        }
    }
}

h2.section-header {
    text-align: center;
    font-size: rem-calc(34);
    font-weight: 400;
    color: #2f2f38;
    margin-bottom: 0;
}

h3.section-header {
    text-align: center;
    font-size: rem-calc(30);
    font-weight: 300;
    color: #32373f;
    margin-bottom: rem-calc(20);
}

h4.section-header {
    text-align: center;
    font-size: rem-calc(24);
    font-weight: 300;
    color: #32373f;
    margin-bottom: rem-calc(20);
}