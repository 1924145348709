
/**
 * Engage buttons found in the frontpage header
 */
.engage-button {
    margin: rem-calc(20 20);
    position: relative;
    display: inline-block;

    color: white;
    font-size: rem-calc(18);
    font-weight: 200;
    text-align: center;

    transition: all .2s ease-out;

    &.club {
        color: white;
        padding: rem-calc(14 42);
        border-radius: 4px;
        box-shadow: 0 1px 1px rgba(0,0,0,0.06),inset 0 0 0 1px rgba(0,0,0,0.15);
        background: #62dd4e;
        background: linear-gradient(to bottom,  #62dd4e 0%,#4ba937 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#62dd4e', endColorstr='#4ba937',GradientType=0 );

        &:hover {
            color: white;
            background: #69df57;
            background: linear-gradient(to bottom,  #69df57 0%,#51b43a 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#69df57', endColorstr='#51b43a',GradientType=0 );
        }

        &:active {
            color: white;
            background: #4ba937;
            background: linear-gradient(to bottom,  #4ba937 0%,#62dd4e 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4ba937', endColorstr='#62dd4e',GradientType=0 );
        }
    }

    &.player {
        color: white;
        padding: rem-calc(14 42);
        border-radius: 4px;
        box-shadow: 0 1px 1px rgba(0,0,0,0.06);
        background: #6083dd;
        background: linear-gradient(to bottom,  #6083dd 0%, #4f6cb6 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#62dd4e', endColorstr='#4ba937',GradientType=0 );

        &:hover {
            color: white;
            background: #658ae8;
            background: linear-gradient(to bottom,  #618ce7 0%, #5074bf 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#69df57', endColorstr='#51b43a',GradientType=0 );
        }

        &:active {
            color: white;
            background: #6083dd;
            background: linear-gradient(to bottom,  #567dce 0%,#6388e5 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4ba937', endColorstr='#62dd4e',GradientType=0 );
        }
    }
    &.infob {
        color: #333333;
        text-decoration: none;
        border-bottom: thin solid rgba(128, 167, 242, 0);

        &:hover {
            color: #333333;
            border-bottom: thin solid #333333;
        }

        &:active {
            color: #333333;
        }
    }

    &.action {
        color: white;
        padding: rem-calc(14 42);
        border-radius: 4px;
        box-shadow: 0 1px 1px rgba(0,0,0,0.06),inset 0 0 0 1px rgba(0,0,0,0.15);
        background: #62dd4e;
        background: linear-gradient(to bottom,  #62dd4e 0%,#4ba937 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#62dd4e', endColorstr='#4ba937',GradientType=0 );

        &:hover {
            background: linear-gradient(to bottom,  #69df57 0%,#51b43a 100%);
        }

        &:active {
            background: linear-gradient(to bottom,  #4ba937 0%,#62dd4e 100%);
        }
    }
}

.button-slim-white {
    height: rem-calc(50);
    line-height: rem-calc(50);
    margin-bottom: rem-calc(20);
    text-align: center;
    border-radius: 2px;
    background-color: #fff;
    border-bottom: 2px solid #ececec;
    color: $primary-color;
    transition: background .2s ease;
    display: block;

    i {
        font-size: rem-calc(32);
        vertical-align: middle;
        margin-right: rem-calc(12);
        margin-top: rem-calc(-5);
        color: $primary-color;
    }

    &:hover {
        background-color: #c8ffbc;
        border-bottom: 2px solid #b5e7aa;
    }

    &:active {
        border-bottom: thin solid #b5e7aa;
        position: relative;
        top: 1px;
    }
}

.greenb {
    background-color: transparent;
    border: 1px solid #53A745;
    color: #53A745;
    border-radius: 2px;

    &:hover {
        background-color: rgba(83, 167, 69, 0.25);
        color: #53A745;
    }

    &:active {
        position: relative;
        top: 1px;
    }
}

.green {
    background-color: $primary-color;
    border-bottom: 2px solid #418636;
    border-radius: 2px;
    position: relative;

    &:hover {
        background-color: #5cbd4d;
        border-bottom: 2px solid #53A745;
    }
    &:active {
        background-color: #5cbd4d;
        border-bottom: 0;
        top: rem-calc(2);
    }
}

.contact-button {
    line-height: rem-calc(50);
    color: #fff;
    background: #53a745;
    min-width: rem-calc(250);
    border-radius: 2px;
    transition: all .1s ease-out;
    cursor: pointer;
    padding: rem-calc(0 40);

    &:hover {
        background: scale-color(#53a745, $lightness: 10%);
    }
}