.testimonials {
    @extend .site-section;
}

#testimonials-carousel {
    margin: rem-calc(20 0);
    padding: rem-calc(40 0);
    background: url('/img/sportbundels-background-04.jpg') center center;
    background-size: cover;
    background-attachment: fixed;
}

.testimonial {
    position: relative;
    padding: rem-calc(20);
    background-color: rgba(255,255,255,.95);
    border-radius: 3px;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,.35);
    @include clearfix;

    @media #{$small-only} {
        margin: 0 5%;
    }

    @media #{$large-up} {
        padding: rem-calc(60 60 70);
    }
}

.testimonial-quote_icon {
    color: #bbc5cc;
    font-size: rem-calc(220);
    font-family: join('Arial', $body-font-family);

    &.open {
        text-align: left;
        margin: rem-calc(-50 0 -160 -20);

        @media #{$medium-up} {
            margin: rem-calc(-80 0 -160 0);
        }
    }

    &.close {
        text-align: right;
        margin: rem-calc(-70 -20 -220 0);

        @media #{$medium-up} {
            margin: rem-calc(-70 0 -220 0);
        }
    }
}

.testimonial-author-quote { }

.testimonial-author-logo {
    text-align: center;
    float: left;
    margin: rem-calc(-10 60 0 60);

    img {
        height: 100%;
        width: 100%;
        max-width: rem-calc(120);
        max-height: rem-calc(120);
    }
}

.testimonial-author-info {
    text-align: center;

    @media #{$medium-up} {
        text-align: left;
    }
}

.testimonial-author-name {
    margin-top: rem-calc(20);
    font-weight: 400;
    color: #333;
    font-size: rem-calc(32);
    display: block;
}

.testimonial-author-title {
    font-weight: 200;
    color: #999;
    font-size: rem-calc(27);
    display: block;
}