@mixin sticky-footer($footer-height, $root-selector: unquote("#root"), $root-footer-selector: unquote("#root_footer"), $footer-selector: unquote("#footer")) {
    html, body {
        height: 100%;
    }
    #{$root-selector} {
        clear: both;
        min-height: 100%;
        height: auto !important;
        height: 100%;
        margin-bottom: -$footer-height;
        #{$root-footer-selector} {
            height: $footer-height;
        }
    }
    #{$footer-selector} {
        clear: both;
        position: relative;
        height: $footer-height;
    }
}

// Sticky footer
@include sticky-footer(116px);

#footer {
    color: #085f2c;
    font-size: rem-calc(14);
    // margin: rem-calc(100) 0 0;
    @include clearfix;

    @media #{$small-only} {
        text-align: center;
    }

    .bottom {
        padding: rem-calc(40 0);
        line-height: 1;
        opacity: 0.35;
        transition: all .35s ease-out;

        &:hover {
            opacity: 1.0;
        }
    }
}

.footer-social {
    text-align: center;
    margin: rem-calc(24) 0;
    @include clearfix;

    a {
        font-size: rem-calc(33);
        display: inline-block;
        color: $primary-color;
        transition: all .2s ease;
        margin: rem-calc(0);

        &:hover {
            color: scale-color($primary-color, $lightness: 10%);
        }
    }
}

#copyright {
    display: block;
    line-height: 1;

    @media #{$medium-up} {
        float: left;
    }
}

#sitemap {

    @media #{$medium-up} {
        float: right;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        font-size: rem-calc(14);
        line-height: 1;

        @media #{$small-only} {
            margin: rem-calc(20 0 0);
            font-size: rem-calc(18);
            color: #53a745;
        }
    }

    li {
        display: block;

        @media #{$small-only} {
            margin: rem-calc(8) 0;
        }

        @media #{$medium-up} {
            float: left;
            margin-left: rem-calc(40);
        }
    }

    a {
        transition: color .2s ease;

        &:hover {
            color: $primary-color;
        }
    }
}