.brand-color {
    color: $primary-color;
}

.brand-font {
    font-family: join('Aleo', $body-font-family);
    font-weight: 400;
}

.st {
    text-decoration: line-through;
}

.uppercase {
    text-transform: uppercase;
}