.app-modules {
    @extend .site-section;
    
    h2{

        @media #{$medium-up} {
            font-size: rem-calc(32);
        }
    }
}

#modules-carousel {
    background-color: #fafafa;
}

.row{
    &.three-modules{
        margin:20px auto 40px;
    }
}

.app-module {
    padding: rem-calc(30 0);
    text-align: center;
    color: $primary-color;
    font-weight: 400;

    @media #{$small-only} {
        margin: rem-calc(0 15);
    }

    .module-icon {
        width: rem-calc(100);
        margin: rem-calc(0 0 10);
        font-size: rem-calc(56);
        line-height: rem-calc(100);
        border-radius: 50%;
        border: thin solid $primary-color;
        transition: all .3s ease-out;
        background-color: transparent;
        text-align: center;
    }

    .module-name {
        font-size: rem-calc(24);
        color: inherit;
        font-weight: 300;
    }

    .module-description {
        font-size: rem-calc(16);
        color: #484c53;
        font-weight: 400;
        text-align: center;

        b{
            color: #111;
            font-weight: 600;
        }
    }

    .module-more {
        line-height: rem-calc(42);
        color: #53a745;
        border-radius: 30px;
        transition: all .2s ease-out;
        display: block;
        border:thin solid #53a745;
        opacity: .25;


        .icon-chevron-right {
            color: rgba(83, 167, 69, 1);
            width: rem-calc(30);
            height: rem-calc(30);
            line-height: rem-calc(28);
            font-size: rem-calc(16);
            border: 0;
            transition: all .3s ease-out;
            background-color: transparent;
        }

        &:hover {
            background-color: rgba(83, 167, 69, 0.2);

            .icon-chevron-right {
                margin: rem-calc(0 0 0 6);
            }
        }
    }

    &:hover .module-more {
        opacity: 1;
    }
}