.information-section {
    @extend .site-section;

    h1 {
        font-family: $body-font-family;
        line-height: 1.35;
        font-weight: 300;
        color: $primary-color;
        margin-bottom: rem-calc(20);
        text-align: center;
    }

    h2 {
        font-size: rem-calc(32);
        color: inherit;
        font-weight: 400;
    }

    h3 {
        font-size: rem-calc(16);
        color: #333;
        font-weight: 600;
    }

    .module {
        margin: 0;
        text-align: center;
        font-family: $body-font-family;
        color: $primary-color;
        font-weight: 400;

        i {
            color: inherit;
        }
    }

    i {
        width: 100px;
        height: 100px;
        margin: 0 0 rem-calc(10);
        font-size: rem-calc(48);
        line-height: rem-calc(100);
        border-radius: 50%;
        border: 1px solid $primary-color;
        transition: all .3s ease-out;
        background-color: transparent;
    }
}
#information-intro{
    margin-bottom: 95px;
}

#ledenadministratie h1 {
    white-space: nowrap;
}

.information-content{
    margin:30px 0 0;
}

.information-content-lead{
    p{
        font-size: rem-calc(16);
    }
}

.information-content-advantages{
    margin:20px 0;
}

.information-content-advantage{
    margin:10px 0 20px;

    h3{
        font-size: rem-calc(16);
        text-align: left;
        margin-bottom:4px;
        font-weight: 600;

    }
    p{
        line-height: 30px;
        font-size: rem-calc(15);
        margin:0;

    }
}


.hero{
/*
    max-width: none;
*/
}

.information-image{
    margin: 30px 0 40px;
    padding: 0;

    .hero{

        &.administration{

        }
    }
}