#masthead {
    @include clearfix;
    width: 100%;
    position: relative;
    z-index: 999;
    padding: rem-calc(0);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.66) 0%,rgba(255,255,255,0) 100%); /* W3C */
}

#logo {
    float: left;
    line-height: rem-calc(131);
    display: block;

    img {
        width: rem-calc(210);

        @media #{$medium-up} {
            width: rem-calc(270);
        }

        @media #{$large-up} {
            width: rem-calc(340);
        }
    }
}

.login {
    position: absolute;
    top:0;
    right: 0;

    a {
        color: white;
        line-height: 30px;
        padding: 10px 20px;
        background-color: $primary-color;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        transition: all 0.2s;

        &:hover {
            color: white;
            background-color: #5cbd4d;
        }
    }
}

#nav {
    margin-top: 51px;
    text-align: right;
    @include clearfix;
    display: none;

    @media #{$large-up} {
        display: block;
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            float: left;

            a {
                margin-left: rem-calc(18);
                margin-right: rem-calc(18);
                font-size: rem-calc(18);
                line-height: 1;
                color: $body-font-color;
                transition: color .2s ease-out;

                &.active, &:hover {
                    color: $primary-color;
                }
            }
            &.grey{
                margin-left: rem-calc(18);
                margin-right: rem-calc(18);
                font-size: rem-calc(18);
                color: #d1b4b6;
                cursor: pointer;
            }
        }
    }
}

#mobile-nav-toggle {
    padding: rem-calc(6) rem-calc(12) rem-calc(12);
    background-color: $primary-color;
    color: #fff;
    font-size: rem-calc(28);
    font-weight: 400;
    cursor: pointer;
    margin-top: rem-calc(46);
    transition: background .35s ease-out;
    display: block;

    @media #{$large-up} {
        display: none;
    }

    &:hover {
        background-color: darken($primary-color, 5%);
    }
}

#mobile-nav {
    clear: both;
    width: 100%;
    margin-bottom: rem-calc(16);
    display: none;

    @media #{$large-up} {
        display: none !important;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li a {
            padding: rem-calc(8) rem-calc(12);
            font-size: rem-calc(18);
            color: #fff;
            border-bottom: thin solid #fff;
            display: block;
            background-color: $primary-color;
            transition: background .35s ease;

            &:hover {
                background-color: darken($primary-color, 5%);
            }
        }
    }
}
