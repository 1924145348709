.seperator-strip {
    min-height: 204px;
    padding: rem-calc(60 15);
    margin: rem-calc(80 0);
    background-attachment: fixed;

    h2 {
        margin: rem-calc(20 0);
        text-shadow: 0 0 4px rgba(35, 35, 35, .35);
        text-align: center;
        color: #fff;
        font-weight: 300;
        font-size: rem-calc(32);
        transition: all .25s ease-out;
    }
}