.small-articles {
  margin-top: rem-calc(50);
}

.small-article {
  position: relative;
  margin-bottom: rem-calc(40);

  @media #{$medium-up} {
    float: left;
  }

  @media #{$medium-only} {
    width: 50%;
    padding: rem-calc(0 0 0 15);

    &:nth-of-type(2n+1) {
      padding-left: rem-calc(0);
      padding-right: rem-calc(15);
      clear: left;
    }
  }

  @media #{$large-up} {
    width: 33.3%;
    padding: rem-calc(0 15 0 15);

    &:nth-of-type(3n+1) {
      padding-left: rem-calc(0);
      padding-right: rem-calc(15);
      clear: left;
    }

    &:nth-of-type(3n) {
      padding-left: rem-calc(15);
      padding-right: rem-calc(0);
    }
  }
}

.small-article-image {
  width: 100%;
  height: 180px;
}

.small-article-subline {
  color: #777;
  font-style: italic;
  font-size: rem-calc(18);
  line-height: 1.35;
  margin: rem-calc(12 0);
}

.small-article-title {
  font-size: rem-calc(22);
  font-weight: 700;
  line-height: 1.35;
  margin: rem-calc(8 0 12);
  //    color: #000;

  a {
    color: inherit;
  }
}

.small-article-meta {
  color: #aaa;
  font-size: rem-calc(13);
  font-weight: 300;
}

.small-article-meta-date {
  color: #666;
  font-weight: 400;
  float: left;
}

.small-article-meta-author {
  float: right;
}

// Full articles

.article {
  margin-top: rem-calc(50);
  position: relative;
}

.article-image-container {
  position: relative;
  margin: rem-calc(0 0 16);
}

.article-image {
  width: 100%;
  height: auto;
}

.article-subline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: rem-calc(20 60);
  text-align: center;
  color: #fff;
  font-style: italic;
  font-size: rem-calc(21);
  line-height: 1.35;
  text-shadow: 0 0 5px rgba(0, 0, 0, .7);
}

.article-subline-background {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC40Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.4)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#66000000', GradientType=0);
  width: 100%;
  height: 140px;
  position: absolute;
  bottom: 0;
}

.article-meta {
  color: #aaa;
  font-size: rem-calc(13);
  font-weight: 300;
  margin: rem-calc(0 0 24);
  @include clearfix;
}

.article-meta-date {
  color: #666;
  font-weight: 400;
  float: left;
}

.article-meta-author {
  float: right;
}

.article-title {
  font-size: rem-calc(30);
  font-weight: 600;
  line-height: 1.25;
  margin: rem-calc(8 0 20);
  //    color: #000;

  a {
    color: inherit;
  }
}

.article-body {
  font-size: rem-calc(15);
  line-height: 2;

  //  h1 {
  //    font-family: "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  //    line-height: 1.35;
  //    font-weight: 600;
  //    color: #53a745;
  //    margin-bottom: 1.25rem;
  //    text-align: left;
  //  }

  h2 {
    font-size: 2rem;
    font-weight: 200;
    color: #53a745;
    margin: rem-calc(15 0 5);
    text-align: left;
  }

  h3 {
    font-size: 1.625rem;
    color: #53a745;
    margin-bottom: .25rem;
    text-align: left;
    font-weight: 200;
  }

  h4 {
    font-size: 1.375rem;
    color: #53a745;
    margin-bottom: 1.2rem;
    text-align: left;
  }

  p, ul {
    font-size: rem-calc(15);
    line-height: 2;
  }

  figure {
    margin: 0;
  }
  figure, img {
    width: 100%;
  }
}

.article-footer {
  margin: rem-calc(40 0);
  padding: rem-calc(20 0);
  border-top: thin solid #e6e6e6;
}

.article-social-button {
  background-color: #961e64;
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0));
  border: thin solid rgba(0, 0, 0, .1);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: rem-calc(13);
  font-weight: 700;
  margin: rem-calc(0 5 0 0);
  padding: rem-calc(6 12);
  position: relative;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  transition: background .2s;

  i {
    margin-right: rem-calc(16);
    font-size: rem-calc(20);
    vertical-align: middle;
  }

  &:hover,
  &:active,
  &:focus {
    color: #fff;
  }

  &.facebook {
    background-color: #3b5998;
    &:hover {
      background-color: scale-color(#3b5998, $lightness: 10%);
    }
  }
  &.twitter {
    background-color: #00aced;
    &:hover {
      background-color: scale-color(#00aced, $lightness: 10%);
    }
  }
  &.googleplus {
    background-color: #dd4b39;
    &:hover {
      background-color: scale-color(#dd4b39, $lightness: 10%);
    }
  }
  &.linkedin {
    background-color: #007bb6;
    &:hover {
      background-color: scale-color(#007bb6, $lightness: 10%);
    }
  }
}

.article-comments {
  margin: rem-calc(40 0 0);
  padding: rem-calc(40 0 0);
  border-top: thin solid #e6e6e6;
}

.sidebar {
  border-left: 1px solid #e6e6e6;
  padding: rem-calc(10 0 16 16)
}

.sidebar-title {
  font-size: rem-calc(24);
  font-weight: 600;
  margin: rem-calc(0 0 2);
  line-height: 1.2;
}

.sidebar-subtitle {
  font-size: rem-calc(16);
  font-weight: 400;
  color: #aaa;
  margin: 0;
  line-height: 1.1;

  a {
    color: inherit;
    &:hover {
      color: $primary-color;
    }
  }
}

.article-tweets {
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    padding: rem-calc(0 0 0 36);
    margin: rem-calc(10 0);
    display: block;
    position: relative;
    font-size: rem-calc(13);
    line-height: 1.4;

    small {
      display: block;
      font-size: rem-calc(12);
      color: #aaa;

      a {
        color: inherit;

        &:hover {
          color: $primary-color;
        }
      }
    }

    &:before {
      font-family: 'sportbundels';
      content: "\ea91";
      position: absolute;
      top: rem-calc(-2);
      left: rem-calc(4);
      color: rgba(0, 172, 237, .7);
      font-size: rem-calc(20);
    }
  }
}