&.bg1 {
    background: url('/img/sportbundels-background-01.jpg') center center;
    background-size: cover;
}

&.bg2 {
    background: url('/img/sportbundels-background-02.jpg') center center;
    background-size: cover;
}

&.bg3 {
    background: url('/img/sportbundels-background-03.jpg') center center;
    background-size: cover;
}

&.bg4 {
    background: url('/img/sportbundels-background-04.jpg') center center;
    background-size: cover;
}

&.bg5 {
    background: url('/img/sportbundels-background-05.jpg') center center;
    background-size: cover;
}

&.bg6 {
    background: url('/img/sportbundels-background-06.jpg') center center;
    background-size: cover;
}

&.bg7 {
    background: url('/img/sportbundels-background-07.jpg') center center;
    background-size: cover;
}