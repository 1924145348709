.popup-background {
    background-color: rgba(255, 255, 255, .65);
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 98;
    top: 0;
    left: 0;
}

.popup-box {
    background-color: #fff;
    padding: rem-calc(20);
    position: fixed;
    left: 50%;
    z-index: 100;
    height: auto;
    margin-left: -40%;
    width: 80%;
    border: thin solid #ccc;
    box-shadow: rgba(0, 0, 0, 0.4) 0 0 10px;
    top: 5.55556rem;

    &.small {
        margin-left: rem-calc(-250);
        width: rem-calc(500);
    }
}