@charset "UTF-8";

@import "settings";
@import "foundation/components/grid";
//@import "foundation/components/accordion";
@import "foundation/components/alert-boxes";
//@import "foundation/components/block-grid";
//@import "foundation/components/breadcrumbs";
//@import "foundation/components/button-groups";
@import "foundation/components/buttons";
//@import "foundation/components/clearing";
//@import "foundation/components/dropdown";
//@import "foundation/components/dropdown-buttons";
//@import "foundation/components/flex-video";
@import "foundation/components/forms";
//@import "foundation/components/icon-bar";
//@import "foundation/components/inline-lists";
//@import "foundation/components/joyride";
//@import "foundation/components/keystrokes";
//@import "foundation/components/labels";
//@import "foundation/components/magellan";
//@import "foundation/components/orbit";
@import "foundation/components/pagination";
//@import "foundation/components/panels";
//@import "foundation/components/pricing-tables";
//@import "foundation/components/progress-bars";
//@import "foundation/components/range-slider";
//@import "foundation/components/reveal";
//@import "foundation/components/side-nav";
//@import "foundation/components/split-buttons";
//@import "foundation/components/sub-nav";
//@import "foundation/components/switches";
//@import "foundation/components/tables";
//@import "foundation/components/tabs";
//@import "foundation/components/thumbs";
//@import "foundation/components/tooltips";
//@import "foundation/components/top-bar";
@import "foundation/components/type";
//@import "foundation/components/offcanvas";
@import "foundation/components/visibility";


/**-----------------
    HTML
-----------------**/
html{
    overflow: -moz-scrollbars-vertical;
    overflow: scroll;
}

/** --------------------
  * Standards
  * --------------------
  */

#primary {
    @media #{$medium-up} {
        margin: 0 0 rem-calc(100);
    }
}

.page-header {
    font-weight: 300;

    small {
        color: #b8b8b8;
    }
}


/** -----------------------
 * PROBEREN
 * -----------------------
 */

#proberen-result {
    font-size: rem-calc(28) !important;
    margin: rem-calc(8 0);
    color: $primary-color;
}

/** -----------------------
 *   Error pages
 *  -----------------------
 */

.big-list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: rem-calc(20)
}

/** -----------------------
 * Imports
 * ------------------------
 */

@import 'components/_alerts';
@import 'components/_backgrounds';
@import 'components/_buttons';
@import 'components/_carousel';
@import 'components/_cover';
@import 'components/_forms';
@import 'components/_fonts';
@import 'components/_modals';
@import 'components/_seperators';
@import 'components/_typography';

@import 'sections/_features';
@import 'sections/_header';
@import 'sections/_sections';
@import 'sections/_modules';
@import 'sections/_our-vision';
@import 'sections/_startup';
@import 'sections/_testimonials';

@import 'pages/_informatie';
@import 'pages/_prijzen';
@import 'pages/_contact';
@import 'pages/_proberen';
@import 'pages/_articles';

@import 'masthead';
@import 'footer';