@font-face {
    font-family: 'sportbundels';
    src: url('/sportbundels/fonts/sportbundels.eot?-kowg2r');
    src: url('/sportbundels/fonts/sportbundels.eot?#iefix-kowg2r') format('embedded-opentype'),
    url('/fonts/sportbundels/sportbundels.woff?-kowg2r') format('woff'),
    url('/fonts/sportbundels/sportbundels.ttf?-kowg2r') format('truetype'),
    url('/fonts/sportbundels/sportbundels.svg?-kowg2r#sportbundels') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'sportbundels';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block; // Hack
    vertical-align: middle;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-apple:before {
    content: "\f179";
}

.icon-windows:before {
    content: "\f17a";
}

.icon-android:before {
    content: "\f17b";
}

.icon-menu:before {
    content: "\e600";
}

.icon-mail-circle:before {
    content: "\ea86";
}

.icon-google-plus:before {
    content: "\ea88";
}

.icon-google-plus-circle:before {
    content: "\ea8a";
}

.icon-facebook:before {
    content: "\ea8c";
}

.icon-facebook-circle:before {
    content: "\ea8e";
}

.icon-twitter:before {
    content: "\ea91";
}

.icon-twitter-circle:before {
    content: "\ea93";
}

.icon-sportbundels-beoordeling:before {
    content: "\e615";
}

.icon-sportbundels-kader:before {
    content: "\e616";
}

.icon-sportbundels-ledenadministratie:before {
    content: "\e617";
}

.icon-sportbundels-planning:before {
    content: "\e618";
}

.icon-sportbundels-profiel:before {
    content: "\e619";
}

.icon-sportbundels-scouting:before {
    content: "\e61a";
}

.icon-sportbundels-teams:before {
    content: "\e61b";
}

.icon-sportbundels-video:before {
    content: "\e61c";
}

.icon-sportbundels-smartphone:before {
    content: "\e601";
}

.icon-sportbundels-helpdesk:before {
    content: "\e602";
}

.icon-sportbundels-ledenregistratie:before {
    content: "\e603";
}
